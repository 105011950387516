<template>
  <div class="terms-card">
    <span class="terms-card__name">{{ name }}</span>
    <span class="terms-card__description">
      {{ description || "-" }}
    </span>
  </div>
</template>

<script>
export default {
  name: "TermsCard",
  props: {
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.terms-card {
  background-color: $white;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  box-shadow: $box-shadow-small;
  border: 2px solid $secondary-400;
  cursor: pointer;

  &__name {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: $secondary-500;
  }

  &__description {
    font-size: 14px;
    line-height: 24px;
    color: $primary;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
