<template>
  <div class="terms-page" data-v-step="3">
    <BackTitle
      :class="['xs', 'sm'].includes($mq) ? 'mb-40' : 'mb-64'"
      @click="goBack"
    >
      Terms & conditions
    </BackTitle>
    <Guard permission="terms.create" v-slot="{ isAvailable }">
      <IconButton
        v-if="isAvailable"
        is-raised
        icon="plus"
        class="mb-32"
        @click="goToCreation"
      >
        New terms & conditions
      </IconButton>
    </Guard>
    <div data-v-step="5">
      <Loader v-if="isLoading" class="mx-auto" size="m" color="primary" />
      <div v-else-if="!termsList.length" class="empty">
        You haven't created terms & conditions yet
      </div>
      <div v-else class="grid-2">
        <TermsCard
          v-for="terms in termsList"
          :key="terms.id"
          :name="terms.name"
          :description="terms.description"
          @click.native="goToEditing(terms)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle";
import { mapActions, mapState } from "vuex";
import TermsCard from "@/components/terms/TermsCard";
import Guard from "@/components/common/Guard";
export default {
  name: "TermsPage",
  components: { Guard, TermsCard, BackTitle },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      termsList: (state) => state.terms.list,
    }),
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchTerms();
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      fetchTerms: "terms/fetchTerms",
    }),
    goBack() {
      this.$router.push({
        name: "Settings",
      });
    },
    goToCreation() {
      this.$router.push({
        name: "CreateTerms",
      });
    },
    goToEditing(terms) {
      this.$router.push({
        name: "EditTerms",
        params: {
          id: terms.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.terms-page {
  display: flex;
  flex-direction: column;
}
</style>
